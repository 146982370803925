import React from 'react';
import { StyledContactSection} from "./styles";
import { Container} from "../Container";

function ContactSection({className, children}) {
    return (
        <StyledContactSection>
            <Container>
                {children}
            </Container>
        </StyledContactSection>
    );
}

export default ContactSection;