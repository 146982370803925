import { Section } from "../HomePage/styles"
import styled from "styled-components"
import { colors} from "../../const/colors";


export const StyledContactSection = styled(Section)`
  position:relative;
  .contact-form {
    width:100%;
    max-width:800px;
    margin:60px auto auto auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding:25px;
    background: ${colors.lightBg}
  }
    .error {
      display: block;
      color:red;
      margin: 0 0 10px 10px;
      font-size: 16px;
    }
    .input-field, .select-field {
      background:white;
      margin-bottom:15px;
    }
    .select-field {
      padding-right:20px;
    }
    input, select {
      width:100%;
      padding:25px;
      border:none;
      background:white;
      outline-color: ${colors.greenColor}
    }
  textarea {
    padding:25px;
    width:100%;
    border:none;
    outline-color: ${colors.greenColor}
  }
  .contact-info {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    max-width:800px;
    margin:60px auto auto auto;
  }
  .p-absolute {
    display:none;
    @media screen and (min-width: 992px) {
      display:block;
    }
    position:absolute;
    width:100%;
    margin:auto;
  }
  .left {
    left:-100px;
    bottom:30%;
    max-width:300px;
    @media screen and (min-width: 992px) {
      display:none;
    }
    @media screen and (min-width: 1110px) {
      display:block;
      left:-200px;
    }
    @media screen and (min-width: 1250px) {
      left:-100px;
    }
  }
  .right {
    right:100px;
    bottom:-100px;
    max-width:300px;
  }
  .left-top {
    @media screen and (min-width: 992px) {
      display:none;
    }
    @media screen and (min-width: 1110px) {
      display:block;
      right:-15%;
    }
    @media screen and (min-width: 1250px) {
      right:-10%;
    }
    @media screen and (min-width: 1450px) {
      right:0;
    }
    top:0;
    right:0;
    max-width:300px;
  }
`