import React, {useState, useContext, useEffect, useRef} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactSection from "../components/ContactPage"
import {SectionTitle} from "../components/SectionTitle/styles";
import { colors } from "../const/colors"
import {ButtonGf, ButtonLink, ButtonWrapper} from "../components/Button/styles";
import {InfoBox} from "../components/Footer/styles";
import {FaEnvelope, FaMapMarkerAlt, FaPhoneAlt} from "react-icons/fa";
import CircleOne from "../images/rightTopContactBg.svg";
import CircleTwo from "../images/leftContactBg.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import { ImCheckmark, ImNotification } from "react-icons/im"
import axios from "axios"
import GlobalContext from "../context/GlobalContext";
import queryString from "query-string"
import { navigate } from "gatsby"
import localData from "../localization/contact.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }
const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

let defFormData = {
    name: "",
    email: "",
    subject: "",
    text: ""
}
const GfContact = ({data, location, pageContext}) => {
    const { lang } = pageContext
    // const metaData = data.template.locales.find(l => l.iso === lang)
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    const gContext = useContext(GlobalContext)
    // const [formSubjects, setFormSubjects] = useState(data.globalJson.contactFormSubjects) ;
    const queryParams = queryString.parse(location.search)
    const topElement = useRef(null)
    const [subject, setSubject] = useState(queryParams.sub || null)
    const [formSubmited, setFormSubmited] = useState(false)
    const [isSendingMessage, setIsSendingMessage] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        name: [],
        email: [],
        subject: [],
        text: []
    })
    const [serverError, setServerError] = useState("")
    const [formData, setFormData] = useState(defFormData)
    const handleOnFocus = (type,e) => {
        setValidationErrors(prevState => {
            return {...prevState, [type]: []}
        })
    }
    useEffect(() => {
        if(queryParams.sub) {
            if(gContext.additionalPackages.length > 0) {
                setFormData(prevState => {
                    return { ...prevState , additionalPackages: gContext.additionalPackages , subject: queryParams.sub }
                })
            }else {
                setFormData(prevState => {
                    return { ...prevState , subject: queryParams.sub }
                })
            }

        }
    },[])
    const handleOnChange = (type,e) => {
        setFormData({...formData, [type]: e.target.value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setValidationErrors({ name: [], email: [], subject: [], text: []})
        if(!formData.name) {
            setValidationErrors(prevState => {
                return { ...prevState, name: [...prevState.name, "Name is required"]}
            })
        }
        if(!formData.email) {
            setValidationErrors(prevState => {
                return { ...prevState, email: [...prevState.email, "Email is required"]}
            })
        }
        if(!formData.subject) {
            setValidationErrors(prevState => {
                return { ...prevState, subject: [...prevState.subject, "Subject is required"]}
            })
        }
        if(!formData.text) {
            setValidationErrors(prevState => {
                return { ...prevState, text: [...prevState.text, "Message is required"]}
            })
        }
        if( formData.name === "" || formData.email === "" || formData.subject === "" || formData.text === "" ) {
            return
        }
        const fields = []
        for (let type in formData) {
            fields.push({
                value: formData[type],
                type: type.toUpperCase()
            })
        }

        const dataToSend = {
            identifier: process.env.WEBFRONT_ID,
            iso: pageContext.lang,
            fields: fields
        }

        try {
            setIsSendingMessage(true)
            //mzbkqapb
            //meqpwlaj
            //https://formspree.io/f/meqpwlaj
            const response = await axios.post(process.env.FORM_CONTACT_API_URL,
                {...dataToSend },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    mode: 'cors',
                    cache: 'no-cache',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                }
            )
            // const response = await fetch(process.env.FORM_CONTACT_API_URL, {
            //     method: 'POST',
            //     mode: 'cors',
            //     cache: 'no-cache',
            //     redirect: 'follow',
            //     referrer: 'no-referrer',
            //     body: JSON.stringify(dataToSend), // body data type must match "Content-Type" header
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            // const data = await response.json()
            topElement.current.scrollIntoView({
                behavior:"smooth"
            })
            setFormData(defFormData)
            gContext.setAdditionalPackages([])
            setFormSubmited(true)
            setIsSendingMessage(false)
        }catch (error) {
            setFormSubmited(true)
            setIsSendingMessage(false)
            // setServerError(error.message)
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                setServerError(error.response.data.message)
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error);
        }
    }
    return <Layout location={location} lang={lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <ContactSection>
            <SectionTitle color={colors.greenColor} align="center">{localData.pageTitle[lang]}</SectionTitle>
            <p ref={topElement} style={{textAlign:"center", maxWidth:"600px", margin:"auto"}}>{localData.description[lang]}</p>
            <div className="contact-form">
                {!formSubmited ? <form onSubmit={handleSubmit} method="post" action="https://formspree.io/f/meqpwlaj">
                    <input type="text" name="_gotcha" style={{display:"none"}} />
                    {gContext.additionalPackages.length > 0 && <input type="hidden" name="additionalPackages" value={gContext.additionalPackages}/> }
                    <div className="input-field">
                        <input onFocus={handleOnFocus.bind(this, "name")} onChange={handleOnChange.bind(this,"name")} type="text" name="name" value={formData.name} placeholder={localData.form.nameField[lang]}/>
                    </div>
                    {validationErrors.name.length > 0 && validationErrors.name.map( (error, index) => {
                        return <span key={index} className="error">{error}</span>
                    })}
                    <div className="input-field">
                        <input onFocus={handleOnFocus.bind(this, "email")} onChange={handleOnChange.bind(this,"email")} type="email" name="_replyto" value={formData.email} placeholder={localData.form.emailField[lang]}/>
                    </div>
                    {validationErrors.email.length > 0 && validationErrors.email.map( (error,index) => {
                        return <span key={index} className="error">{error}</span>
                    })}
                    <div className="select-field">
                        <select defaultValue={subject ? subject : null} onFocus={handleOnFocus.bind(this, "subject")} onChange={handleOnChange.bind(this,"subject")} style={{color:colors.lightGrey}} name="subject" id="subject">
                            <option  value="">{localData.form.subjectField[lang]}</option>
                            {data.globalJson.newContactFormSubjects.map(subject => {
                                return <option key={subject.value[lang]} value={subject.value[lang]}>{subject.label[lang]}</option>
                            })}
                        </select>
                    </div>
                    {validationErrors.subject.length > 0 && validationErrors.subject.map( (error, index) => {
                        return <span key={index} className="error">{error}</span>
                    })}
                    <div style={{marginBottom:"30px"}} className="input-field">
                        <textarea onFocus={handleOnFocus.bind(this, "text")} onChange={handleOnChange.bind(this,"text")} name="text" id="" cols="30" rows="10" placeholder={localData.form.messageField[lang]} value={formData.message} />
                    </div>
                    {validationErrors.text.length > 0 && validationErrors.text.map( (error, index) => {
                        return <span key={index} className="error">{error}</span>
                    })}
                    <ButtonWrapper align="center">
                        <ButtonGf disabled={isSendingMessage} type="submit" color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{ isSendingMessage ? localData.form.button.sendingLabel[lang] : localData.form.button.label[lang]}</ButtonGf>
                    </ButtonWrapper>
                </form> : <div className="success-contact-form" style={{display: "flex", textAlign:"center", flexDirection:"column", justifyContent: "center", alignItems: "center", width:"100%", height:"100vh"}}>
                    <div>
                        {serverError ? <div>
                                <div><ImNotification size="75px" color="red" /></div>
                                <span style={{display:"inline-block", marginTop:"30px", fontSize: "2rem", fontWeight:"bold", marginBottom:"30px"}}>{serverError}</span>
                        </div> :
                        <>
                            <div><ImCheckmark size="75px" color={colors.greenColor} /></div>
                            <span style={{display:"inline-block", marginTop:"30px", fontSize: "2rem", fontWeight:"bold", marginBottom:"30px"}}>Thank you for contacting Green Flamingo!</span>
                            <br/>
                            <p>We are processing your request. <br/> You can expect to hear from our team shortly. </p>
                        </>
                        }
                        <ButtonGf onClick={e => {setFormSubmited(false); setSubject(null); navigate("/contact") }}>Go back</ButtonGf>
                    </div>
                </div>}
            </div>
            <div className="contact-info">
                <InfoBox textColor={colors.grayColor} type="phone" color={colors.grayColor} icon={<FaPhoneAlt size="1.2em" color={colors.greenColor} />} text="+34 655 922 998" />
                <InfoBox color={colors.grayColor} icon={<FaMapMarkerAlt size="1.2em" color={colors.greenColor} />} text="Barcelona, Spain" />
                <InfoBox textColor={colors.grayColor} type="email" color={colors.grayColor} icon={<FaEnvelope size="1.2em" color={colors.greenColor} />} text="info@greenflamingobcn.com" />
            </div>
            <img className="left-top p-absolute" src={CircleOne} alt="circles two"/>
            <img className="left p-absolute" src={CircleTwo} alt="circles two"/>
            <img className="right p-absolute" src={CircleThree} alt="circles three"/>
        </ContactSection>
    </Layout>
}

export const query = graphql`
    query($templateName: String! ) {
        template(name : { eq : $templateName }) {
            locales {
                metaTitle
                metaDescription
                metaKeywords
                iso
            }
        }
        globalJson {
            newContactFormSubjects {
                label {
                    en
                    es
                }
                value {
                    en
                    es
                }
            }
        }
    }
`


export default GfContact
